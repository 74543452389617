const activeLib = [];

export const getActiveLib = () => activeLib;

const getGoogleMapKey = () => {
    if (ServerChecker.isFlowCloud || ServerChecker.isGlobal) {
        return 'AIzaSyCjtpi59GMt3q8fKeOVP1Poh1-JlovcaeE';
    } else {
        //enterprise
        return 'AIzaSyADjbtMn46r9DGFyo_ZRz3c6fOXzuOKWCw';
    }
}

export const getGoogleMap = () => loadScript(
    () => window.google?.maps?.places,
    `https://maps.googleapis.com/maps/api/js?libraries=places&key=${getGoogleMapKey()}`
);
//export const getGoogleDrive = () => loadScript(() => window.google?.picker, `https://apis.google.com/js/platform.js?google-drive`);
export const getRequest = () => loadScript(() => window.superagent, 'superagent/superagent.min.js');
export const getSortable = () => loadScript(() => window.Sortable, 'sortablejs/Sortable.min.js')
export const getOneDrive = () => loadScript(() => window.OneDrive, 'oneDrive/OneDrive.js')
export const getJuiChart = () => loadScript(() => window.graph, 'jui-chart-master/chart.js');
export const getCKEditor = () => loadScript(() => window.CKEDITOR, 'ckeditor/ckeditor.js');
export const getHtml2Pdf = () => loadScript(() => window.html2pdf, 'html2pdf/html2pdf.bundle.min.js');
export const getEmoji = async () => {
    await loadScript(() => window.picmo, 'picmo/picmo.js');
    await loadScript(() => window.picmoTwemoji, 'picmoTwemoji/picmoTwemoji.js');
}

export const getGoogleDrive = async () => {
    if (Often.isGlobal()) {
        await loadScript(() => window.google?.account, `https://apis.google.com/js/api.js`)
        await loadScript(() => window.google?.account, `https://accounts.google.com/gsi/client`)
    } else {
        await loadScript(() => window.google?.picker, `https://apis.google.com/js/platform.js?google-drive`)
    }
}
export const getToastChart = () => {
    const func = loadScript(() => window.toastui?.Chart, '@toast-ui/toastui-chart.min.js');
    loadStyle('', '@toast-ui/toastui-chart.min.css');
    return func;
}

export const getExcelLib = async () => {
    const [saveAs, XLSX] = await Promise.allSettled([
        loadScript(() => window.saveAs, 'excel/FileSaver.js'),
        loadScript(() => window.XLSX, 'excel/xlsx.style.min.js')
    ]);
    if (!(saveAs.status === 'fulfilled' && XLSX.status === 'fulfilled')) {
        throw new Error('Library issue : 엑셀 다운 불가!');
    }
    return {saveAs: saveAs.value, XLSX: XLSX.value};
}

export const getFullCalendar = async () => {
    //jquery, moment 필수
    //tooltip 이벤트는 라이브러리 내부에 커스텀 되어있음. 버전업 시 참고
    await loadScript(() => window.moment, 'moment/moment.min.js');
    const func = await loadScript(() => window.$.fullCalendar, 'fullcalendar-3.10.2/fullcalendar.min.js');
    await loadScript(() => Object.keys(window.$.fullCalendar.locales).length > 1, 'fullcalendar-3.10.2/locale-all.js');
    loadStyle('fullcalendar', 'fullcalendar-3.10.2/fullcalendar.min.css');
    return func;
}

export const getEWSFullCalendar = async () => {
    //jquery, moment 필수
    await loadScript(() => window.moment, 'moment/moment.min.js');
    const func = await loadScript(() => window.EwsFullCalendar, 'fullcalendar/lib/main.min.js');
    loadStyle('EWSFullcalendar', 'fullcalendar/lib/main.css');
    return func;
}

export const getVideoViewer = async () => {
    const func = await loadScript(() => window.videojs, 'videojs/video.min.js');
    loadStyle('VideoJs', 'videojs/video-js.min.css');
    return func;
}

export const getFlatpickr = async () => {
    const func = await loadScript(() => window.flatpickr, 'flatpickr/flatpickr.min.js');
    await loadScript(() => window.ko, 'flatpickr/l10n/ko.js');
    await loadScript(() => window.ja, 'flatpickr/l10n/ja.js');
    await loadScript(() => window.es, 'flatpickr/l10n/es.js');
    await loadScript(() => window.vi, 'flatpickr/l10n/vi.js');
    await loadScript(() => window.de, 'flatpickr/l10n/de.js');
    await loadScript(() => window.fr, 'flatpickr/l10n/fr.js');
    flatpickr.localize(flatpickr.l10ns[Internationalization.getCurrentLanguage()]);
    loadStyle('Flatpickr', 'flatpickr/flatpickr.min.css');
    return func;
}

export const getConfirmDate = async () => {
    const func = await loadScript(() => window.confirmDatePlugin, 'confirmDate/confirmDate.js');
    loadStyle('ConfirmDate', 'confirmDate/confirmDate.css');
    return func;
}


export function loadScript(getValueCallback, src) {
    const base = !window.basePath ? '' : window.basePath
    if (src.includes("https://")) {
        //skip
    } else if (src.startsWith("util")) {
        src = base + '/flow-renewal/module/' + src + "?ver=" + (window.version || '');
    } else {
        src = base + '/flow-renewal/module/lib/' + src + "?ver=" + (window.version || '');
    }

    return new Promise((resolve, reject) => {
        const val = getValueCallback();
        if (val && activeLib.includes(src)) {
            resolve(val);
            return;
        }

        const s = document.createElement('script');
        let r = false;
        s.type = 'text/javascript';
        s.src = src;
        s.async = true;
        s.onerror = (err) => reject(err, s);
        s.onload = s.onreadystatechange = function () {
            if (!r && (!this.readyState || this.readyState === 'complete')) {
                r = true;
                activeLib.push(src);
                resolve(getValueCallback());
            }
        };
        const t = document.getElementsByTagName('script')[0];
        t.parentElement.insertBefore(s, t);
    });
}

function loadStyle(id, href) {
    if ($(`#${id}`).length > 0) return;
    const pathPrefix = window.basePath ?? '';
    href = pathPrefix + '/flow-renewal/module/lib/' + href + "?ver=" + (window.version || '');
    const s = document.createElement('link');
    s.id = id;
    s.rel = 'stylesheet';
    s.href = href;
    const t = document.getElementsByTagName('link')[0];
    t.parentElement.insertBefore(s, t);
}
