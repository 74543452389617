function open(url, target) {
    window.open(url, target);
    // if (isElectron()) {
    //     var w = screen.availWidth;
    //     var h = screen.availHeight;
    //     var left = screenLeft + (screen.availWidth - w) / 2;
    //     var top = screenTop + (screen.availHeight - h) / 2;
    //     fn().openElectronProject(url, COLABO_SRNO, -1, -1, left, top, false);
    // } else {
    //     window.open(url, target);
    // }
}

//부모가 존재하면 거기다가 열고 아니면 새로 열어요!
function openParent(url, target) {
    if (window?.opener) {
        window.opener.location.href = url;
        window.opener.focus();
    } else {
        open(url, target);
    }
}

export {open, openParent}