import {isBrowser, exist} from './Often.js'


let sel;

export const Selection = () => sel || window.getSelection();
export const setSelection = (selection) => sel = selection;

export const FocusNode = (selection) => selection.focusNode;
export const KeyValue = (sel) => {
    const {focusOffset, focusNode} = sel || Selection();
    let value;
    try {
        value = focusNode.textContent.substring(focusOffset - 1, focusOffset);
    } catch {
        value  = '';
    }
    return value;
}

export function getTargetNextWord(selection = window.getSelection(), target = '') {
    if (!exist(target)) return '';

    const {focusNode, focusOffset} = selection;
    const textContent = focusNode.textContent;
    let word = textContent.substring(0, focusOffset);
    const idx1 = word.lastIndexOf(target);
    word = idx1 > -1 ? word.substring(idx1 + 1) : word;
    const idx2 = word.indexOf(' ');
    word = idx2 > -1 ? word.substring(0, idx2) : word;
    return word
}

/**
 * @description 현재 포커스 노드에 targetEl을 추가
 * @Note 보완할점 : Paste이벤트로 붙여넣을 경우 Event Stop을 해줘야함.
 */
export function insertBeforeFocusNode(selection, targetEl) {
    const range = selection.getRangeAt(0);
    if (isBrowser("ie")) {
        range.endContainer.insertBefore(targetEl, null);
    } else {
        const node = document.createTextNode("\u00A0");
        range.insertNode(node);
        range.commonAncestorContainer.insertBefore(targetEl, node);
    }
    range.collapse(false);
}

/**
 * @description 현재 포커스 노드에 splitText를 시작으로 커서까지 targetEl로 변환
 */
export function changeSplitNode(selection, targetEl, splitText) {
    const {focusNode, focusOffset} = selection;
    const textContent = focusNode.textContent;

    const focusSubText = textContent.substr(0, focusOffset);
    const targetText = focusSubText.split(splitText).pop();
    const targetTextLength = targetText.length + 1;
    const splitAnchorOffset = focusOffset - targetTextLength;

    let range = createTargetRange(focusNode, splitAnchorOffset, focusOffset);
    range.commonAncestorContainer.splitText(focusOffset);
    range.commonAncestorContainer.splitText(splitAnchorOffset);

    const node = document.createTextNode("\u00A0");
    range.commonAncestorContainer.insertBefore(targetEl, range.endContainer);
    range.commonAncestorContainer.insertBefore(node, range.endContainer);
    range.endContainer.deleteData(0, range.endContainer.length || 0);

    // selection 초기화
    // Todo. 사파리에서 selection 체크를 잘 못해서 직접 제어해줌
    range = document.createRange();
    range.setStart(node, 1);
    selection.removeAllRanges();
    selection.addRange(range);
}

/**
 * @description 현재 포커스 노드에 targetText를 찾아 targetEl로 변환
 */
export function changeTargetTextNode(selection, targetEl, targetText) {
    const {focusNode, focusOffset} = selection;
    const textContent = focusNode.textContent;

    const focusSubText = textContent.substr(0, focusOffset);
    const subTextLength = focusSubText.length;
    const targetTextLength = targetText.length;
    const splitAnchorOffset = subTextLength - targetTextLength;

    let range = createTargetRange(focusNode, splitAnchorOffset, focusOffset);
    range.commonAncestorContainer.splitText(focusOffset);
    range.commonAncestorContainer.splitText(splitAnchorOffset);

    const node = document.createTextNode("\u00A0");
    range.commonAncestorContainer.insertBefore(targetEl, range.endContainer);
    range.commonAncestorContainer.insertBefore(node, range.endContainer);
    range.endContainer.deleteData(0, range.endContainer.length || 0);

    // selection 초기화
    // Todo. 사파리에서 selection 체크를 잘 못해서 직접 제어해줌
    range = document.createRange();
    range.setStart(node, 1);
    selection.removeAllRanges();
    selection.addRange(range);
}

/**
 * @param focusNode Selection객체의 FocusNode
 * @param startOffset TargetNode에 시작점(Offset)
 * @param endOffset TargetNode에 끝점(Offset)
 */
function createTargetRange(focusNode, startOffset, endOffset) {
    const range = document.createRange();
    range.setStart(focusNode, startOffset);
    range.setEnd(focusNode, endOffset);
    return range;
}

export function getPosition(selection) {
    let range, rects, rect;
    let x = 0, y = 0;
    if (!selection.rangeCount) return {top: y, left: x}
    range = selection.getRangeAt(0).cloneRange();
    range.collapse(true);
    rects = range.getClientRects();
    rect = rects.length > 0 ? rects[0] : "";
    x = rect.left || 0;
    y = rect.top || 0;
    return {top: y, left: x}
}
